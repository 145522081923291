import { HashRouter, Routes, Route} from 'react-router-dom';

import Home from './pages/home/Home'
import About from './pages/about/About'
import Candidate from './pages/candidate/Candidate'
import Client from './pages/client/Client'
import Contact from './pages/contact/Contact'
import Blog from './pages/blog/Blog'
import Navbar from './components/Navbar'
import NotFound from './pages/notFound/NotFound'
import Footer from './components/Footer'
import Policy from './pages/policy/Policy'
import Hblog from './pages/home/Hblog'
import TrustedBy from './components/TrustedBy'
import ScrollToTop from './components/ScrollToTop'
import BackToTopButton from './components/BackToTopButton'
import SignUp from './pages/accounts/SignUp';


// Import your individual blog post components
import Blog1 from './pages/blogPages/Blog1'
import Blog2 from './pages/blogPages/Blog2'
import Blog3 from './pages/blogPages/Blog3'
import Blog4 from './pages/blogPages/Blog4'
import Blog5 from './pages/blogPages/Blog5'
import Blog6 from './pages/blogPages/Blog6'





const App = () => {

  return  (
    <HashRouter>
    <ScrollToTop />
      <Navbar/>
      <Routes>
        <Route index element = {<Home/>}/>
        <Route path="about" element = {<About/>}/>
        <Route path="candidate" element = {<Candidate/>}/>
        <Route path="client" element = {<Client/>}/>
        <Route path="contact" element = {<Contact/>}/>
        <Route path="blog" element = {<Blog/>}/>
        {/*hblog path */}
        <Route path="blogPage/blog1" element={<Blog1 />} />
        <Route path="blogPage/blog2" element={<Blog2 />} />
        <Route path="blogPage/blog3" element={<Blog3 />} />
        {/*hblog path */}
        {/*full blog path */}
        <Route path="blog/blogPages/blog1" element={<Blog1 />} />
        <Route path="blog/blogPages/blog2" element={<Blog2 />} />
        <Route path="blog/blogPages/blog3" element={<Blog3 />} />
        <Route path="blog/blogPages/blog4" element={<Blog4 />} />
        <Route path="blog/blogPages/blog5" element={<Blog5 />} />
        <Route path="blog/blogPages/blog6" element={<Blog6 />} />
        {/*full blog path END*/}
        <Route path="policy" element = {<Policy/>}/>
        <Route path="o-hub" element = {<SignUp/>}/>
        <Route path="*" element = {<NotFound/>}/>
      </Routes>
      <TrustedBy/>
      <Hblog/>
      <Footer/>
      <BackToTopButton />
    </HashRouter>
  );
}

export default App